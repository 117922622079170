.home-client-logos {
  padding-top: 20px;
  padding-bottom: 20px;
}

.client-logos {
  height: 160px;
  padding: 0 10px;
}

.client-logos-techno{
  height:90px;
  padding: 0 10px;
}

.zensark-logo {
  height: 60px;
  @media (max-width:599px) {
      padding: 50px 30px 100px 20px;
  }
}
