/* .main-navbar {
    position: absolute;
    z-index: 0;
} */

.mobile-navbar {
  /* position: relative; */
  z-index: 1;
  /* transform: translateY(-65px); */

  @media (min-width: 992px) {
    display: none;
  }
}

.book-demo-button:hover {
  background-color: #032d60 !important;
  /* border: 2px solid #0176d3; */
}

.mobile-menu-content {
  position: relative;
  z-index: 1;
  overflow: scroll;
  height: 100%;
  /* padding-top: 20px; */
}

.light-version-logo {
  height: 43px;
}

.menu-icon-button {
  @media (min-width: 992px) {
    /* visibility: hidden; */
    display: none;
  }
}

.navbar-stack {
  position: relative;
}

.navbar-stack-one {
  position: relative;
}

.navbar-menu-items:hover {
  background-color: #d0e8fe;
  color: #032d60;
}

.search-textfield .MuiOutlinedInput-root fieldset {
  border: none;
}

.main-navbar-services.hovered::before,
.main-navbar-services:focus-within::before,
.main-navbar-services:hover::before {
  width: 100%;
}

.main-navbar-services::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: #00a2df;
  transition: 0.4s ease width;
}

.main-navbar-services.hovered::after,
.main-navbar-services:focus-within::after,
.main-navbar-services:hover::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  clear: both;
}

.main-navbar-services-serviceitem.hovered::before,
.main-navbar-services-serviceitem:hover::before {
  width: 100%;
}

.main-navbar-services-serviceitem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: #00a2df;
  transition: 0.4s ease width;
}

.main-navbar-services-serviceitem.hovered::after,
.main-navbar-services-serviceitem:hover::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  clear: both;
}

.main-navbar-services-salesforceitem.hovered::before,
.main-navbar-services-salesforceitem:hover::before {
  width: 100%;
}

.main-navbar-services-salesforceitem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: #00a2df;
  transition: 0.4s ease width;
}

.main-navbar-services-salesforceitem.hovered::after,
.main-navbar-services-salesforceitem:hover::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  clear: both;
}

.main-navbar-services-mulesoftitem.hovered::before,
.main-navbar-services-mulesoftitem:hover::before {
  width: 100%;
}

.main-navbar-services-mulesoftitem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: #00a2df;
  transition: 0.4s ease width;
}

.main-navbar-services-mulesoftitem.hovered::after,
.main-navbar-services-mulesoftitem:hover::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  clear: both;
}

.main-navbar-services-digibeeitem.hovered::before,
.main-navbar-services-digibeeitem:hover::before {
  width: 100%;
}

.main-navbar-services-digibeeitem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: #00a2df;
  transition: 0.4s ease width;
}

.main-navbar-services-digibeeitem.hovered::after,
.main-navbar-services-digibeeitem:hover::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  clear: both;
}

.social-icons {
  color: #032d60;
}

.social-icons:hover {
  color: #0176d3;
}

.copyrightLink:hover {
  color: blue;
}

.mobile-menu-drawer {
  @media (min-width: 900px) {
    display: none;
  }
}
.main-navbar-services-serviceitem,
.main-navbar-services-mulesoftitem,
.main-navbar-services-salesforceitem {
  cursor: pointer;
}
