.blog-fab-button:hover {
    color: white;
    background-color: #032d60 !important;
}

.custom-carousel .control-arrow {
    display: none !important;
}

.blog-outlined-button:hover {
    color: white;
    background-color: #032d60 !important;
    border-color: #032d60 !important;
}

.blog-slides-div {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 20px;
}

.blog-slides-slider {
    border-radius: 20px;
}

.blog-cards-banner {
    padding: 80px 100px;
    @media (max-width:1199px) {
        padding: 35px 50px;
    }
}