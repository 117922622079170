.service-banner-one {
    padding: 100px 100px 50px 100px;
    @media (max-width:1199px) {
        padding: 130px 25px 25px 25px;
    }
}

.service-banner-thumb-one {
    width: 100%;
    @media (max-width:374px) {
        width: 90%;
    }
}

.service-banner-two {
    padding: 80px 100px;
    @media (max-width:1199px) {
        padding: 35px 50px;
    }
}

.service-banner-thumb-two {
    width: 100%;
    @media (max-width:374px) {
        width: 90%;
    }
}

.service-icons {
    width: 15%;
}

.why-us-section {
    padding: 50px 100px;
    @media (max-width:899px) {
        padding: 50px 20px;
    }
}

.service-banner-three {
    padding: 50px;
    @media (max-width:1199px) {
        padding: 30px;
    }
}

.partner-banner-one {
    padding: 50px 0px 0px 0px;
    @media (max-width:1199px) {
        padding: 30px 0px 0px 0px;
    }
}