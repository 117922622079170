@font-face {
  font-family: "AvantGardeBold";
  src: url("./assets/fonts/Avant-Garde-Bold.woff") format("truetype");
}
@font-face {
  font-family: "SF";
  src: url("./assets/fonts/SF-normal-400-100.woff") format("truetype");
}
@font-face {
  font-family: "ESFBold";
  src: url("./assets/fonts/ESFBold-normal-400-100.woff") format("truetype");
}
@font-face {
  font-family: "SFBold";
  src: url("./assets/fonts/SFBold-normal-400-100.woff") format("truetype");
}
@font-face {
  font-family: "Salesforce";
  src: url("./assets/fonts/SalesforceSansRegular-normal-400-100.woff")
    format("truetype");
}
@font-face {
  font-family: "Salesforce-Light";
  src: url("./assets/fonts/SalesforceSans-normal-400-100.woff")
    format("truetype");
}

@font-face {
  font-family: "ESF-normal";
  src: url("./assets/fonts/ESF-normal-400-100.woff") format("truetype");
}
.main-div {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.icon {
  color: #0176d3;
}

.icon:hover {
  color: #032d60;
}
.text {
  color: #042c60;
}

.text:hover {
  color: #0176d3;
}

.big {
  line-height: 5;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 10%;
  width: 100%;
}

.BoxBackdround {
  background: #e2f4fe;
}
.aboutBackground {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(249, 253, 255, 1) 32%,
    rgba(236, 248, 255, 1) 100%
  );
}

.backgroundBottom {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    172deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 248, 255, 1) 100%
  );
}

.home-fab-button:hover {
  color: white;
  background-color: #002196 !important;
  border-radius: 30px;
}

.svg {
  display: flex;
}
.svg:hover {
  fill: rgb(14, 9, 57);
}
@media only screen and (max-width: 411px) {
  .button-about {
    margin-top: 10px !important;
  }
}

.arrow {
  display: flex;
  border-radius: 50%;
}

.arrow:hover .left,
.arrow:hover .right {
  border: 0.1em solid #002196;
  background-color: #002196;
}

.arrow:hover .left:after,
.arrow:hover .right:after {
  border-top: 0.2em solid #fff;
  border-right: 0.2em solid #fff;
  background-color: #002196;
}

.left {
  display: flex;
  justify-content: center;
  width: 2.5em;
  height: 2.5em;
  border: 0.1em solid #002196;
  border-radius: 50%;
}

.left:after {
  content: "";
  display: flex;
  justify-content: center;
  margin-top: 0.8em;
  margin-left: 0.3em;
  width: 0.6em;
  height: 0.6em;
  border-top: 0.2em solid #002196;
  border-right: 0.2em solid #002196;
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.right {
  display: flex;
  justify-content: center;
  width: 2.5em;
  height: 2.5em;
  border: 0.1em solid #002196;
  border-radius: 50%;
}

.right:after {
  content: "";
  display: flex;
  justify-content: center;
  margin-top: 0.8em;
  margin-left: -0.2em;
  width: 0.6em;
  height: 0.6em;
  border-top: 0.2em solid #002196;
  border-right: 0.2em solid #002196;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container {
  position: relative;
}

.card-image {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay .About-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container:hover .overlay {
  opacity: 1;
}
.overlay .About-item:not(:last-child) {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #fff;
}
.icon:hover .arrow-animation {
  transform: translateX(5px);
}

.textField {
  border: 1px solid black;
}

.container-form {
  background-image: url("https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Common%20Images/dots.svg?ref_type=heads");
}

.icon-div {
  margin-right: 5px;
  margin-left: 5px;
}
#diamond-narrow {
  border: 10px solid transparent;
  border-bottom: 15px solid #cc3c44;
  position: absolute;
  top: 40px;
  z-index: 1;
}

.arrows {
  color: #ffffff;
}

.arrows:hover {
  color: #f4f5f6;
}
.arrows:hover .arrow-animation {
  transform: translateX(5px);
}

/* dots loading */

.loading-dots {
  text-align: center;
  z-index: 5;

  .dot {
    height: 2px;
    margin-top: 20px;
    position: relative;

    opacity: 0;
    animation: showHideDot 20s ease-in-out infinite;

    &.one {
      animation-delay: 0.1s;
    }

    &.two {
      animation-delay: 0.2s;
    }

    &.three {
      animation-delay: 0.4s;
    }
    &.four {
      animation-delay: 0.4s;
    }
    &.five {
      animation-delay: 0.6s;
    }
    &.six {
      animation-delay: 0.6s;
    }
  }
}

@keyframes showHideDot {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }
  80% {
    opacity: 2;
  }
  100% {
    opacity: 1;
  }
}

.button {
  border: 0;
  position: relative;
}

.hoverMebottom:after {
  position: absolute;

  left: 0%;
  width: 0;
  height: 2px;
  background: #01044c;
  display: block;
  content: "";
  transition: width 0.5s ease-in-out;
}

.hoverMebottom:hover:after {
  width: 100%;
}

.icons {
  color: #0176d3 !important;
}

.icons:hover {
  color: #01044c !important;
}

.navContent {
  height: 35px;
  width: 100%;
  background-color: #ecebeb;
}

/* blog search */

.input-wrapper {
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.input {
  border-style: none;
  outline: none;
  color: #fff;
  background-color: transparent;
  border-bottom: 2px solid #0962a5;
}

.input::placeholder {
  color: #000;
}

.iconSearch:focus.input,
.input:focus {
  box-shadow: none;
  width: 180px;

  background-color: transparent;
  border-bottom: 3px solid #0962a5;
}

.blogBackground {
  background: #141765;
  background: linear-gradient(
    0deg,
    rgba(20, 23, 101, 1) 0%,
    rgba(10, 12, 65, 0.9878545168067226) 100%
  );
}

.search-bar {
  display: flex;
  align-items: center;
  transition: width 0.3s ease-in-out;
  /* Modify as needed */
}

.search-bar.expanded {
  width: 200px;
  /* Adjust as needed */
}

.search-field {
  display: flex;
  align-items: center;
}

.loading-container {
  display: flex;
  align-items: center;
}
.blog-cont12 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.wrapper {
  flex-grow: 1;
}
.blog-card__listcomp {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.blog-card__wrapper {
  flex-grow: 1;
}
.custom-paper__forblog .blog-para:hover {
  color: #0962a5;
  transform: "translateY(-5px)";
}
/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	display: flex;
	align-items: center;
  
}
.PhoneInputInput::placeholder,input::placeholder{
  font-family: "AvantGardeBold";
  font-size: 14px;
  font-weight: 400;
}
.PhoneInputInput::placeholder{
  color: #939292;
}
.PhoneInputInput {
border-color: lightgray ;
	flex: 1;
  border-radius: 4px;
border-style: solid;
	min-width: 0;
  padding: 18px;
}
.PhoneInputInput:hover{
  border-width: 1px;
  border-color: #000;
}
.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	display: block;
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}


.PhoneInputCountry {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px 15px;
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: var(--PhoneInputCountrySelectArrow-width);
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}